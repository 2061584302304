html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
}

.container.text-muted {
    margin: 20px 0;
}
