@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@charset "utf-8";
/* CSS Document */
/*General*/
body {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;
    color: #666;
    font-size: 11px;
    /*background: url(../img/img-bodybg.jpg) no-repeat;*/
    background: #e0edff; /* Old browsers */
    background: -moz-linear-gradient(top, #e0edff 0%, #ffffff 50%, #e0edff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e0edff 0%, #ffffff 50%, #e0edff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e0edff 0%, #ffffff 50%, #e0edff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0edff', endColorstr='#e0edff', GradientType=0 ); /* IE6-9 */
}



    a:hover, a:focus {
        color: #00263e;
        text-decoration: underline;
    }

.white {
    background: #FFF;
    margin-bottom: 20px;
}

.navbar-nav > .open > a, .navbar-nav > .open > a:hover, .navbar-nav > .open > a:focus {
    background: #00263e !important;
}

.drop-menu-bg {
    background: #00263e;
}

.tab-border {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.content-border {
    border: 1px solid #ddd;
}

.tab-text ul li a {
    color: #000 !important;
    text-shadow: none !important;
}

.padding0 {
    padding: 0px;
}

.padding5 {
    padding: 5px !important;
}

.padding15 {
    padding: 15px !important;
}

.padding10 {
    padding: 10px !important;
}

.pad-top7 {
    padding-top: 7px;
}

.overflow {
    overflow: auto;
}

.header {
    width: 100%;
    height: 115px;
    background: url(../img/img-header-bg.png) repeat-x;
    position: relative;
}

h1, .h1 {
    font-size: 20px;
    color: #ffffff;
    position: absolute;
    top: 42px;
    margin: 0;
    left: 15px !important;
    padding: 0;
    font-weight: bold;
}

h3, .h3 {
    font-weight: 300 !important;
}

h4, .h4 {
    color: #222222;
}

.logo {
    background: url(../img/img-logo.png) 0 0 no-repeat;
    height: 38px;
    position: absolute;
    right: 15px;
    top: 33px;
    width: 148px;
}

.pro-name {
    position: absolute;
    left: 2px;
    top: 80px;
    font-size: 13px;
    color: #fff;
}

.navbar {
    margin: 1px 0px;
    /*border-top: #292929 1px solid;
	border-bottom: #292929 1px solid;*/
    background: #5b7f95;
    width: 100%;
    min-height: auto;
    border-radius: 0px !important;
    border-color: none !important;
    border: 0px solid transparent !important;
}

.navbar-collapse {
    padding-left: 0
}

.nav li a {
    padding: 10px 25px 10px 25px;
    text-shadow: 1px 1px 1px #000;
    font-size: 13px;
    color: #fff !important;
}

    .nav li a:hover, .nav > li > a:focus {
        background: #00263e !important;
        color: #fff !important;
    }

.nav li ul li a:hover, .nav > li > ul > li > a:focus {
    background: #5b7f95 !important;
    color: #fff !important;
}

.navbar-collapse {
    padding-right: 0
}

.navbar-nav {
    margin: 0;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    background: #00263e;
    color: #fff;
}

.navbar-default .navbar-toggle {
    /*background-color: #14589f;*/
    padding: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 0px !important;
    border: 0px;
}

.navbar-nav > li {
    border-right: #adc9dd 1px solid;
}

.navbar-nav li:last-child {
    border-right: none;
}

.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    /*background: url(../img/img-footer-bg.jpg) 0 0 repeat;*/
    background: #5b7f95 !important;
    height: 33px;
}

.footer-inner {
    width: 100%;
    position: relative;
    bottom: 0;
    /*background: url(../img/img-footer-bg.jpg) 0 0 repeat;*/
    background: #5b7f95 !important;
    height: 33px;
}

.copyright-txt {
    font-size: 11px;
    color: #fff;
    margin-top: 9px;
}

.version-txt {
    margin-top: 9px;
    font-size: 11px;
    color: #fff;
    /*font-weight: bold;*/
    text-align: right;
}

.form-signin {
    max-width: 380px;
    padding: 19px 29px 29px;
    margin: 0 auto 20px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
    box-shadow: 0px 0px 16px #888888;
}

.txt-ctr {
    max-width: 380px;
    padding: 19px 29px 29px;
    margin: 0 auto 20px;
    left: 45%;
    top: 25%;
    position: absolute;
}

.form-signin .form-signin-heading, .form-signin .checkbox {
    margin-bottom: 10px;
}

.form-signin input[type="text"], .form-signin input[type="email"], .form-signin input[type="password"] {
    font-size: 13px;
    height: auto;
    margin-bottom: 15px;
    padding: 7px 9px;
}

.mg-tb200 {
    margin-top: 200px;
    margin-bottom: 200px;
}

.form-signin1 {
    max-width: 380px;
    padding: 19px 29px 8px;
    margin: 0 auto 20px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
    box-shadow: 0px 0px 16px #888888;
}
    /*.form-signin1 .form-signin-heading, .form-signin1 .checkbox {
	margin-bottom: 10px;
}*/
    .form-signin1 input[type="text"], .form-signin1 input[type="email"], .form-signin1 input[type="password"] {
        font-size: 13px;
        height: auto;
        margin-bottom: 15px;
        padding: 7px 9px;
    }

.log-input {
    /*border-radius: 0px;*/
    border-left: 2px solid #14589f;
    margin: 15px 0px;
}

.lh27 {
    line-height: 27px;
}

.lh20 {
    line-height: 20px;
}

.btn-right {
    text-align: right;
}

.btn-middle {
    text-align: center;
}

.btn-login {
    border: none;
    color: #fff;
    font-size: 13px;
    background: #00263e url(../img/icn-loginarrw.png) 60px 9px no-repeat;
    width: 85px;
    height: 33px;
    text-align: left;
    padding-left: 10px;
    border-radius: 4px;
}

.btn-signin {
    border: none;
    color: #fff;
    font-size: 13px;
    background: #00263e url(../img/icn-loginarrw.png) 162px 9px no-repeat;
    width: 200px;
    height: 33px;
    text-align: left;
    padding-left: 10px;
    border-radius: 4px;
}

    .btn-login:hover {
        background: #666666 url(../img/icn-loginarrw.png) 60px 9px no-repeat;
    }

    .btn-signin:hover {
        background: #666666 url(../img/icn-loginarrw.png) 162px 9px no-repeat;
    }


.btn-submit {
    border: none;
    color: #fff;
    font-size: 13px;
    background: #00263e url(../img/icn-loginarrw.png) 60px 9px no-repeat;
    width: 85px;
    height: 33px;
    text-align: left;
    padding-left: 10px;
    border-radius: 4px;
}

    .btn-submit:hover {
        background: #666666 url(../img/icn-loginarrw.png) 60px 9px no-repeat;
    }

.left {
    float: left;
}

.right {
    float: right;
}

.mg-tb50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

ul.top-links {
    position: absolute;
    right: 5px;
    top: 80px;
    list-style: outside none none;
}

    ul.top-links li {
        color: #fff;
        float: left;
        font-size: 12px;
        line-height: 20px;
        margin-right: 12px;
        padding-left: 22px;
    }

        ul.top-links li:first-child {
            border-right: #3c5e8a 1px solid;
            padding-right: 12px
        }

.top15 {
    top: 15px
}

.icn-welcome {
    background: url(../img/icn-wlcm.png) 0 0 no-repeat;
}

.icn-logout {
    background: url(../img/icn-logout.png) 0 0 no-repeat;
    padding-left: 16px !important;
}

    .icn-logout a {
        color: #dc4405;
    }

        .icn-logout a:hover {
            color: #ffffff;
        }

.p-left25 {
    padding-left: 25px;
}

.p-left0 {
    padding-left: 0px;
}

.p-left5 {
    padding-left: 5px;
}

.p-left18 {
    padding-left: 18px;
}

.p-lr0 {
    padding-left: 0px;
    padding-right: 0px;
}

.pright0 {
    padding-right: 0px;
}

.p-btm5 {
    padding-bottom: 5px;
}

.p-btm15 {
    padding-bottom: 15px;
}

.wd11hd {
    width: 100%;
}

.wd24p {
    width: 24%;
}

.wd32p {
    width: 32%;
}

.wd800 {
    max-width: 800px !important;
}

.ht65 {
    height: 65px !important;
}

.mg-top10 {
    margin-top: 10px !important;
}

.mg-top15 {
    margin-top: 15px;
}

.mg-top40 {
    margin-top: 40px;
}

.mg-top155 {
    margin-top: 155px;
}

.mg-btm0 {
    margin-bottom: 0px;
}

.mg-btm8 {
    margin-bottom: 8px;
}

.mg-btm15 {
    margin-bottom: 15px;
}

.mg-btm16 {
    margin-bottom: 16px;
}

.mg-btm31 {
    margin-bottom: 31px;
}

.mg-btm40 {
    margin-bottom: 40px;
}

.mg-btm49 {
    margin-bottom: 49px;
}

.mg-btm39 {
    margin-bottom: 39px;
}

.mg-btm52 {
    margin-bottom: 52px;
}

.mg-btm74 {
    margin-bottom: 74px;
}

.mg-btm97 {
    margin-bottom: 97px;
}

.mg-btm103 {
    margin-bottom: 103px;
}

.mg-btm121 {
    margin-bottom: 121px;
}

.mg-left0 {
    margin-left: 0px;
}

.mg-left32 {
    margin-left: 32px;
}

.mg-left25 {
    margin-left: 25px;
}

.mg-left225 {
    margin-left: 225px !important;
}

.mg-tb0 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.centered {
    margin: 0 auto;
    float: none;
    clear: both;
}

.icn-export {
    background: #00263e url(../img/icn-excel.png) 10px 8px no-repeat;
    color: #ffffff;
    padding-left: 32px;
}

    .icn-export:hover {
        background: #666666 url(../img/icn-excel.png) 10px 8px no-repeat;
        color: #ffffff;
    }

.icn-submit {
    background: #00263e url(../img/icn-submit.png) 10px 7px no-repeat;
    padding-left: 35px;
    color: #ffffff;
    margin-top: 5px;
}

    .icn-submit:hover {
        background: #666666 url(../img/icn-submit.png) 10px 7px no-repeat;
        color: #ffffff;
    }

.icn-submit1 {
    background: #00263e url(../img/icn-submit.png) 10px 7px no-repeat;
    padding-left: 35px;
    color: #ffffff;
}

    .icn-submit1:hover {
        background: #666666 url(../img/icn-submit.png) 10px 7px no-repeat;
        color: #ffffff;
    }

.icn-cancel {
    background: #00263e url(../img/icn-cancel.png) 10px 6px no-repeat;
    padding-left: 32px;
    color: #ffffff;
}

    .icn-cancel:hover {
        background: #666666 url(../img/icn-cancel.png) 10px 6px no-repeat;
        color: #ffffff;
    }

.icn-close {
    background: #00263e url(../img/icn-close.png) 10px 8px no-repeat;
    padding-left: 32px;
    color: #ffffff;
}

    .icn-close:hover {
        background: #666666 url(../img/icn-close.png) 10px 8px no-repeat;
        color: #ffffff;
    }

.icn-delete {
    background: #00263e url(../img/icn-delete.png) 10px 8px no-repeat;
    padding-left: 32px;
    color: #ffffff;
}

    .icn-delete:hover {
        background: #666666 url(../img/icn-delete.png) 10px 8px no-repeat;
        color: #ffffff;
    }

.icn-ok {
    background: #00263e url(../img/icn-aprv.png) 10px 6px no-repeat;
    padding-left: 32px;
    color: #ffffff;
}

    .icn-ok:hover {
        background: #666666 url(../img/icn-aprv.png) 10px 6px no-repeat;
        color: #ffffff;
    }

.icn-confirm {
    background: #009b77 url(../img/icn-aprv.png) 10px 6px no-repeat;
    padding-left: 32px;
    color: #ffffff;
}

    .icn-confirm:hover {
        background: #666666 url(../img/icn-aprv.png) 10px 6px no-repeat;
        color: #ffffff;
    }

.icn-search {
    background: #00263e url(../img/icn-search.png) 10px 9px no-repeat;
    padding-left: 32px;
    color: #ffffff;
}

    .icn-search:hover {
        background: #666666 url(../img/icn-search.png) 10px 9px no-repeat;
        color: #ffffff;
    }

.icn-save {
    background: #00263e url(../img/icn-save.png) 10px 7px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-save:hover {
        background: #666666 url(../img/icn-save.png) 10px 7px no-repeat;
        color: #ffffff;
    }

.icn-savesub {
    background: #00263e url(../img/icn-save1.png) 10px 7px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-savesub:hover {
        background: #666666 url(../img/icn-save1.png) 10px 7px no-repeat;
        color: #ffffff;
    }

.icn-add {
    background: #00263e url(../img/icn-add.png) 10px 8px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-add:hover {
        background: #666666 url(../img/icn-add.png) 10px 8px no-repeat;
        color: #ffffff;
    }

.icn-edit {
    background: #00263e url(../img/icn-edit.png) 10px 8px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-edit:hover {
        background: #666666 url(../img/icn-edit.png) 10px 8px no-repeat;
        color: #ffffff;
    }

.icn-select {
    background: #00263e url(../img/icn-browse.png) 10px 8px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-select:hover {
        background: #666666 url(../img/icn-browse.png) 10px 8px no-repeat;
        color: #ffffff;
    }

.icn-refresh {
    background: #00263e url(../img/icn-refresh.png) 10px 8px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-refresh:hover {
        background: #666666 url(../img/icn-refresh.png) 10px 8px no-repeat;
        color: #ffffff;
    }

.icn-browse {
    background: #00263e url(../img/icn-browse.png) 10px 9px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-browse:hover {
        background: #666666 url(../img/icn-browse.png) 10px 9px no-repeat;
        color: #ffffff;
    }

.icn-upload {
    background: #00263e url(../img/icn-upload.png) 10px 9px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-upload:hover {
        background: #666666 url(../img/icn-upload.png) 10px 9px no-repeat;
        color: #ffffff;
    }

.icn-pdf {
    background: #00263e url(../img/pdf.png) 10px 6px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-pdf:hover {
        background: #666666 url(../img/pdf.png) 10px 6px no-repeat;
        color: #ffffff;
    }

.icn-print {
    background: #c8102e url(../img/icn-print.png) 10px 6px no-repeat;
    padding-left: 39px;
    color: #ffffff;
}

    .icn-print:hover {
        background: #df5e70 url(../img/icn-print.png) 10px 6px no-repeat;
    }

.icn-clone {
    background: #00263e url(../img/icn-clone.png) 10px 8px no-repeat;
    padding-left: 32px;
    color: #ffffff;
}

    .icn-clone:hover {
        background: #666666 url(../img/icn-clone.png) 10px 8px no-repeat;
        color: #ffffff;
    }

.icn-next {
    background: #00263e url(../img/icn-next.png) 10px 7px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-next:hover {
        background: #666666 url(../img/icn-next.png) 10px 7px no-repeat;
        color: #ffffff;
    }

.icn-pre {
    background: #00263e url(../img/icn-pre.png) 10px 7px no-repeat;
    padding-left: 36px;
    color: #ffffff;
}

    .icn-pre:hover {
        background: #666666 url(../img/icn-pre.png) 10px 7px no-repeat;
        color: #ffffff;
    }


.top-minus1 {
    margin-top: -1px;
}

.txt-right {
    text-align: right;
}

.fw-norm {
    font-weight: normal !important;
}

.fw11 {
    font-size: 11px !important;
}

.fw-text-w {
    color: #fff;
    font-size: 16px;
}

.input-group-addon {
    padding: 6px 6px;
}

.table-head-font {
    color: #ffffff;
    background: #00263e;
}

    .table-head-font th {
        font-weight: normal;
    }

.table-head-font-dashboard {
    color: #000;
    background: #f9f9f9;
}

    .table-head-font-dashboard th {
        font-weight: bold;
    }
/*-------Remove below css for click event---------*/
.page-sidebar ul li {
    position: relative;
}

.page-sidebar ul ul {
    /*	position: absolute;
	visibility: hidden;
	width: 200px;
	top: 0px;
	left: 100%;
	list-style: none;
	z-index: 99;
	padding: 15px;
	line-height: 20px;*/
}

.page-sidebar ul li:hover ul {
    visibility: visible; /*background: #113C70;*/
}

.page-sidebar li:hover {
    z-index: 99;
}

.page-sidebar ul ul li a {
    font-size: 13px;
    color: #fff;
    display: block;
    position: relative;
    margin: 0;
    border: 0px;
    padding: 10px 15px;
    text-decoration: none;
    font-weight: 300; /*border-top: 1px solid #ffffff !important;*/
}

.page-sidebar ul li:hover ul li a:hover {
    color: #fff;
    text-decoration: none;
    background: #14589f;
}

.page-sidebar-head {
    background: #fff;
    padding: 20px 5px;
    text-align: center;
}

.page-bc-bg {
    background: #7c9ac6 !important;
    margin-top: 0px !important;
    color: #ffffff !important;
    text-transform: uppercase;
}

.page-blue-bg {
    background: #7c9ac6;
    padding: 10px;
    margin-bottom: 20px;
    color: #fff;
}

.page-blue-bg1 {
    background: #7c9ac6;
    padding: 10px;
    margin-bottom: 0px;
    color: #fff;
}

.astrik {
    color: #f00;
}

.astrik-left {
    color: #F00;
    float: left;
    width: 35px;
}

.form-box-bg {
    padding: 1px;
    margin-top: 10px;
    border: 1px solid #c0c0c0;
    background: #f0f4f9;
}

.note-txt {
    text-align: center;
    color: #F00;
    margin-bottom: 0px;
    padding: 10px;
    border: 10px solid #7c9ac6;
}

.p-small {
    font-size: 12px;
    margin-bottom: 0px;
}

.btn:hover, .btn:focus {
    color: #fff;
}

.modal-footer {
    text-align: center;
}

.report-box {
    background: #f0f4f9;
    margin-right: 10px;
    border: 1px solid #ddd;
    padding: 20px 0px;
    min-height: 250px;
}

.wd-100 {
    width: 100% !important;
}

.wd-80 {
    width: 80% !important;
    float: left;
}

.wd-90 {
    width: 90% !important;
    float: left;
}

.cal-icon {
    position: relative;
    float: right;
    top: 6px;
}

.line-top {
    border-top: 1px solid #fff;
}

.line-btm {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    color: #003b5c;
}

.report-txtarea {
    height: 200px !important;
    width: 100% !important;
}

.select-box {
    width: 13px;
    height: 13px;
    background: #42567E;
    border: 1px solid #88A3CC;
}

.unselect-box {
    width: 13px;
    height: 13px;
    background: #FFFFFF;
    border: 1px solid #C6C6C6;
    margin-top: 1px;
}

.v-scroll {
    overflow-x: auto;
}

.wd885 {
    width: 885px;
}

.wd750 {
    width: 750px;
}

.tiq-grid table {
    margin-bottom: 0px;
    background: #FFF;
}

    .tiq-grid table td span {
        color: #03F;
        text-decoration: underline;
    }

table.table thead .sorting, table.table thead .sorting_asc, table.table thead .sorting_desc, table.table thead .sorting_asc_disabled, table.table thead .sorting_desc_disabled {
    cursor: pointer;
}

table.table thead .sorting {
    background: rgba(0, 0, 0, 0) url("../img/sort_both.png") no-repeat scroll right center;
}

table.table thead .sorting_asc {
    background: rgba(0, 0, 0, 0) url("../img/sort_asc.png") no-repeat scroll right center;
}

table.table thead .sorting_desc {
    background: rgba(0, 0, 0, 0) url("../img/sort_desc.png") no-repeat scroll right center;
}

table.table thead .sorting_asc_disabled {
    background: rgba(0, 0, 0, 0) url("../img/sort_asc_disabled.png") no-repeat scroll right center;
}

table.table thead .sorting_desc_disabled {
    background: rgba(0, 0, 0, 0) url("../img/sort_desc_disabled.png") no-repeat scroll right center;
}

table td img {
    text-align: center;
}

input[type=radio] {
    margin: 2px 0 0 0 !important;
}

.radio-inline input[type=radio] {
    margin-left: -16px !important;
}

.forgot-txt {
    color: #0097a9;
    text-decoration: underline;
}

.hr {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    border: 0;
    border-top: 1px solid #b4b4b4 !important;
}

.trans-logo {
    background: url(../img/trans-logo.png) 0 0 no-repeat;
    width: 100%;
    height: 100%;
}

.landing-txt {
    text-align: center;
    margin-top: 140px;
    padding: 80px 0px;
    border: 1px solid #ccc;
    border-radius: 16px;
    background: #fff;
}

.grid-input {
    height: 28px !important;
}

.grid-select {
    height: 28px !important;
    padding: 0px 12px !important;
}

.required {
    color: #f00;
}

.calendar {
    float: right;
    background: url(../img/icn-calendar.gif) 0 0 no-repeat;
    width: 22px;
    height: 20px;
    top: 6px;
    position: relative;
}

.cal-input {
    width: 86%;
    /*float: left;*/
}

.font12 {
    font-size: 12px !important;
}

legend {
    font-size: 15px !important;
    color: #003b5c;
}

.timeEntry-control {
    display: none !important;
}

.ui-widget-content {
    background: #fff !important;
}

    .ui-widget-content a {
        color: #2a6496 !important;
        font-size: 11px !important;
    }

.ui-menu-item a {
    position: absolute;
    width: 97%;
    padding: 5px 0px 5px 5px;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-state-focus {
    border: 0px solid #fbcb09 !important;
    background: #00263e !important;
    font-weight: normal !important;
    font-size: 11px !important;
    border-radius: 0px !important;
    color: #fff !important;
    /*position:absolute; width:97%;padding: 5px 0px 5px 5px;*/
}

ui-menu-item .ui-state-hover {
    border: 0px solid #fbcb09 !important;
    background: #00263e !important;
    font-weight: normal !important;
    font-size: 11px !important;
    border-radius: 0px !important;
    color: #fff !important;
    position: absolute;
    width: 97%;
    padding: 5px 0px 5px 5px;
}

.ui-widget-header {
    border: 1px solid #14589f !important;
    background: #00263e !important;
    color: #333333 !important;
    font-weight: normal !important;
    font-size: 11px !important;
}

.ui-datepicker .ui-datepicker-prev span {
    display: block !important;
    position: absolute !important;
    left: 50% !important;
    margin-left: -8px !important;
    top: 51% !important;
    margin-top: -8px !important;
    color: #ffffff !important;
}

.ui-datepicker .ui-datepicker-next span {
    display: block !important;
    position: absolute !important;
    left: 50% !important;
    margin-left: -15px !important;
    top: 51% !important;
    margin-top: -8px !important;
    color: #ffffff !important;
}

.ui-datepicker-next-hover {
    top: 2px !important;
    right: 2px !important;
    cursor: pointer !important;
}

.ui-datepicker-prev-hover {
    top: 2px !important;
    left: 2px !important;
    cursor: pointer !important;
}

.ui-datepicker {
    width: 247px !important;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    border: 1px solid #14589f !important;
    background: #00263e !important;
    color: #fff !important;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    border: 1px solid #14589f !important;
}


/*---------------Pagination------------------*/
.pagerWrap {
    margin: 20px 0;
    height: 25px;
}

.pagerNumWrap {
    float: left;
    height: 25px;
}

    .pagerNumWrap SPAN {
        float: left;
        height: 25px;
        padding: 3px 5px;
    }

    .pagerNumWrap SELECT {
        float: left;
        height: 25px;
    }

UL.grdLinePager {
    direction: ltr;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-right: 20px;
    width: auto;
    float: left;
    font-size: 11px;
}

    UL.grdLinePager LI {
        display: inline-block;
        height: 25px;
        width: 55px;
    }

LI.liBgFirst A, LI.liBgPrev A, LI.liBGNext A, LI.liBGLast A {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
}

LI.liBgCur {
    height: 30px;
    width: 160px !important;
    padding: 0;
    margin: 0;
}

    LI.liBgCur INPUT {
        width: 25px;
        margin: 0;
    }

    LI.liBgCur INPUT, LI.liBgCur SPAN {
        display: block;
        float: left;
        padding: 0px 5px;
        font-size: 11px;
    }

/*-------Remove above css for click event---------*/
/*Links*/
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .padding {
        margin-top: 25px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 480px) and (orientation: landscape) {
    .padding {
        margin-top: 25px;
        margin-bottom: 70px;
    }
}

@media only screen and (min-width: 360px) and (max-width: 640px) {
    .padding {
        margin-top: 100px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 640px) and (orientation: landscape) {
    .padding {
        margin-top: 30px;
        margin-bottom: 65px;
    }
}

.ui-datepicker-trigger {
    padding: 3px;
}

.black-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0.6;
    filter: alpha(opacity=60);
    z-index: 9998;
    background-color: rgb(0, 0, 0);
}

#progress {
    left: 48%;
    position: absolute;
    top: 48%;
    z-index: 1001;
}

/*#progress span {
    left: 48%;
    position: absolute;
    top: 50%;
    z-index: 1001;
}*/

.menuItemSub {
    padding: 4px;
    border: 1px solid #ddd;
    position: relative;
    top: 18px;
    left: 6px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.required-label {
    color: #f00;
}

.ht45 {
    height: 45px;
}

.alert-cust {
    margin-bottom: 0px !important;
    padding: 0px 15px !important;
    margin-top: 5px;
}

#tblRequestors tr td a {
    cursor: pointer;
}

.bld-txt {
    font-weight: bold !important;
}

.error-alert-center {
    position: relative;
    top: 150px;
    right: auto; /*left: 33%;*/
}

.padding-btm40 {
    padding-bottom: 40px;
}

.dataTables_length, .dataTables_paginate paging_full_numbers, .dataTables_info, .dataTables_paginate {
    text-align: left !important;
}

.table-bordered > thead > tr > th {
    padding-right: 35px;
}


.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #5b7f95;
    border-color: #00263e;
}

.pagination > li > a, .pagination > li > span {
    color: #0097a9;
}

.ht450 {
    height: 450px;
}


.demo-container h2 {
    background: #003b5c;
    width: 100%;
    padding: 6px;
    margin-top: 0px;
    color: #fff;
    font-size: 18px;
}

.demo-container_chart h2 {
    background: #003b5c;
    width: 100%;
    padding: 6px;
    margin-top: 0px;
    color: #fff;
    font-size: 18px;
}

.demo-container {
    box-sizing: border-box;
    /*width: 49%;*/
    /* height: 450px; */
    /* padding: 20px 15px 15px 15px; */
    /*padding: 0;*/
    margin: 0 auto 15px auto;
    /*border: 1px solid #ddd;*/
    background: #fff;
    background: linear-gradient(#f6f6f6 0, #fff 50px);
    background: -o-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -ms-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -moz-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -webkit-linear-gradient(#f6f6f6 0, #fff 50px);
}

.demo-container_chart {
    box-sizing: border-box;
    margin: 0 auto 15px auto;
    background: #fff;
    background: linear-gradient(#f6f6f6 0, #fff 50px);
    background: -o-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -ms-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -moz-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -webkit-linear-gradient(#f6f6f6 0, #fff 50px);
}

.mg-btm35 {
    margin-bottom: 35px !important;
}

.panel-primary {
    border-color: #1ab394;
}

    .panel-primary > .panel-heading {
        background-color: #1ab394;
        border-color: #1ab394;
    }

.panel-green {
    background-color: #009b77;
    border-color: #009b77;
}

.panel-yellow {
    background-color: #7a99ac;
    border-color: #7a99ac;
}

.panel-blue {
    background-color: #003b5c;
    border-color: #003b5c;
}

.panel-red {
    background-color: #c8102e;
    border-color: #c8102e;
}

.panel-heading {
    color: #fff;
}

    .panel-heading .huge {
        font-size: 25px;
    }

.panel-success {
    border-color: #1c84c6;
}

    .panel-success > .panel-heading {
        background-color: #1c84c6;
        border-color: #1c84c6;
        color: #ffffff;
    }

.panel-info {
    border-color: #23c6c8;
}

    .panel-info > .panel-heading {
        background-color: #23c6c8;
        border-color: #23c6c8;
        color: #ffffff;
    }

.panel-warning {
    border-color: #f8ac59;
}

    .panel-warning > .panel-heading {
        background-color: #f8ac59;
        border-color: #f8ac59;
        color: #ffffff;
    }

.panel-danger {
    border-color: #ed5565;
}

    .panel-danger > .panel-heading {
        background-color: #ed5565;
        border-color: #ed5565;
        color: #ffffff;
    }

.i-request {
    background: url(../img/icn-request.png) 0 0 no-repeat;
    width: 50px;
    height: 62px;
    display: block;
}

.i-amount {
    background: url(../img/icn-amount.png) 0 0 no-repeat;
    width: 35px;
    height: 62px;
    display: block;
}

.i-invoice1 {
    background: url(../img/icn-invoice1.png) 0 0 no-repeat;
    width: 55px;
    height: 62px;
    display: block;
}

.i-invoice2 {
    background: url(../img/icn-invoice2.png) 0 4px no-repeat;
    width: 75px;
    height: 62px;
    display: block;
}


#form-group-Quantity-urb .control-label {
    padding-top: 8px !important;
    width: 190px !important;
}

#form-group-Quantity-ld .control-label {
    padding-top: 8px !important;
    width: 190px !important;
    /*width: 228px !important;*/
}

.cust-wd228 {
    width: 228px !important;
}

.cust-mrg7 {
    margin-top: 7px;
}

.cust-mrg4 {
    margin-top: 4px;
}
.fixedblck { 
    overflow: hidden;
    float: left;
}

.div-inline {
    display: -webkit-box;
    float: left;
    margin-left: 50px;
}

.div-Cust-lm{
    margin-left: 5px;
}

.div-Cust-rm{
    margin-right: 5px;
}

.help-block {
    color:#a94442;
    margin: 0px !important;
}

.treq-table button.p-column-filter-menu-button.p-link,button.treq-table .p-column-filter-clear-button.p-link{display: none;}
.treqTable button#btnPrint{float: right;
    margin-bottom: 12px;
    margin-top: -30px;}
.p-autocomplete{display: block !important;}
input.p-autocomplete-input.p-inputtext.p-component {
    width: 100% !important;
}
.treqListing .p-datatable .p-datatable-tbody > tr > td{text-align: center;}
.dashboardPage .col-lg-12 .row {background: #fff;}
.treq-table .p-button,.treq-table .p-button:hover{    background: #00263eeb;
    border: 1px solid #00263eeb;}


   .p-datatable svg,.p-calendar svg {
        color: wheat;
    }
    
.p-checkbox .p-checkbox-box.p-highlight {
    background-color: #00263eeb !important;
    border-color: #00263eeb !important;
  }
  .p-checkbox .p-checkbox-box {
    border-color: #00263eeb !important;
  }
  .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
    color: white !important;
  }
  .p-checkbox .p-checkbox-box:hover {
    border-color: #00263eeb !important;
  }
  
  .p-checkbox input[type="checkbox"]:checked + .p-checkbox-box {
    background-color: #00263eeb !important;
    border-color: #00263eeb !important;
  }
  
  .p-inputtext:enabled:hover{
    border-color:#00263eeb;
  }

  thead tr:first-child th {
    background: #00263eeb;
    color: white;
}

  .form-control:focus {
    border-color: #00263eeb;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight{
    color: #00263eeb;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Dim the background */
    z-index: 1000; /* Make sure it covers other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-icon {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .btn-primary,.btn-primary:hover,.btn-primary:active,.btn-primary:focus {
    color: #fff;
    background-color: #00263e;
    border-color: #00263eeb;
}
.p-filter-column input{
    color: #000000b5;
}
.pi{color: #fff;}
.mgleft20 {
    margin-left: 20px;
}
.mrright18 {
    margin-right: 18px;
}
.fleft{float: left;}
p.status-message{    min-width: 300px;
    font-size: 14px;}

.p-datatable .p-paginator svg{
    color: #5b7f95;
}