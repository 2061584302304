.notification-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1050; /* Ensure this is higher than the modal's z-index */
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.notification-content {
  flex-grow: 1;
}

.notification-popup button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.notification-popup.loading {
  background-color: #f0ad4e;
  color: white;
}

.notification-popup.success {
  background-color: #5cb85c;
  color: white;
}

.notification-popup.error {
  background-color: #d9534f;
  color: white;
}


